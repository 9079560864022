import { RoleTypeEnum } from "~/bff/graphql/generated/graphql";

export const roleTypesMap: { [key in RoleTypeEnum]: string } = {
  [RoleTypeEnum.Freelance]: "Freelance",
  [RoleTypeEnum.CaretakingAndFamilyResponsibilities]:
    "Caretaking and Family Responsibilities",
  [RoleTypeEnum.GigEconomyJobs]: "Gig Economy Jobs",
  [RoleTypeEnum.ResearchAndAcademicProjects]: "Research and Academic Projects",
  [RoleTypeEnum.TravelAndExchangePrograms]: "Travel and Exchange Programs",
  [RoleTypeEnum.VolunteerWork]: "Volunteer Work",
};

import { JobTypeEnum } from "~/bff/graphql/generated/graphql";

export const jobTypeMap: { [key in JobTypeEnum]: string } = {
  [JobTypeEnum.Contractor]: "Contractor",
  [JobTypeEnum.FullTime]: "Full Time",
  [JobTypeEnum.Internship]: "Internship",
  [JobTypeEnum.Other]: "Other",
  [JobTypeEnum.PartTime]: "Part Time",
  [JobTypeEnum.PerDiem]: "Per Diem",
  [JobTypeEnum.Seasonal]: "Seasonal",
  [JobTypeEnum.Temporary]: "Temporary",
};

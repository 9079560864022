import { NameEnum } from "~/bff/graphql/generated/graphql";

export const milestoneNameMap: { [key in NameEnum]: string } = {
  [NameEnum.ApplicationReview]: "Application Review",
  [NameEnum.Hired]: "Hired",
  [NameEnum.Interviewing]: "Interview",
  [NameEnum.Offer]: "Offer",
  [NameEnum.PreScreen]: "Pre-Screen",
  [NameEnum.Sourcing]: "Sourcing",
};

import {
  IndustryEnum,
  SubIndustryTypeEnum,
} from "~/bff/graphql/generated/graphql";
import { industryMap } from "./enum-mappers/industry-map";
import { Option } from "~/scalis-components/core/select/select.types";

export type optionsType = {
  label: string;
  value: any;
};

export type optionGrp = {
  label: string;
  value?: any;
  options: optionsType[];
};

export type industryValues = keyof typeof IndustryEnum;

export type SubIndustries = Record<IndustryEnum, SubIndustryTypeEnum[]>;

export const industries: optionsType[] = Object.values(IndustryEnum).map(
  industry => ({
    label: industry,
    value: industry,
  }),
);

export const generateIndustryOptions = () => {
  return Object.values(IndustryEnum).map(value => ({
    label: industryMap[value as IndustryEnum],
    value,
  }));
};

function generateSubIndustriesMap(): SubIndustries {
  const subIndustriesMap: Partial<SubIndustries> = {};

  Object.values(SubIndustryTypeEnum).forEach(subIndustry => {
    const industryPrefix = subIndustry.split("_")[0].toLowerCase();

    Object.values(IndustryEnum).forEach(industry => {
      const normalizedIndustry = industry.toLowerCase().replace(/[^a-z]/g, "");

      if (normalizedIndustry.startsWith(industryPrefix)) {
        if (!subIndustriesMap[industry]) {
          subIndustriesMap[industry] = [];
        }
        subIndustriesMap[industry]!.push(subIndustry);
      }
    });
  });

  return subIndustriesMap as SubIndustries;
}

export const subIndustries = generateSubIndustriesMap();

export default function getIndustryAndSubIndustry() {
  const optionGrp: optionGrp[] = [];
  industries.forEach(industry => {
    optionGrp.push({
      label: industry.label,
      value: industry.value,
      options: subIndustries[industry.value as IndustryEnum].map(
        subIndustry => ({
          label: subIndustry,
          value: subIndustry,
        }),
      ),
    });
  });
  return optionGrp;
}

const formatSubIndustryLabel = (subIndustry: string): string => {
  return subIndustry
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const generateSubIndustriesOptions = (
  industry: IndustryEnum | "",
): Option[] => {
  if (industry === "") return [];
  return subIndustries[industry]?.map(subIndustry => ({
    label: formatSubIndustryLabel(subIndustry),
    value: subIndustry,
  }));
};

import * as filtersConst from "~/scalis-components/team/contexts/candidate-application-filters-context/candidate-application-filters-context.constants";
import { CandidateApplicationFiltersData } from "./use-get-candidate-application-filters-data.types";

export const EMPTY_CANDIDATE_APPLICATION_FILTERS_DATA: CandidateApplicationFiltersData =
  {
    [filtersConst.FIELD_NAME_CANDIDATE_PROFILE_ID]: [],
    [filtersConst.FIELD_NAME_COMPANY]: [],
    [filtersConst.FIELD_NAME_DEGREE]: [],
    [filtersConst.FIELD_NAME_EMAIL]: [],
    [filtersConst.FIELD_NAME_JOB_TITLE]: [],
    [filtersConst.FIELD_NAME_MAJORS]: [],
    [filtersConst.FIELD_NAME_SCHOOLS]: [],
    [filtersConst.FIELD_NAME_SKILLS]: [],
    [filtersConst.FIELD_NAME_SCREENED_BY]: [],
  };

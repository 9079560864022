import { FilterTab } from "./filter-bar-context.types";
import { initialState } from "./filter-bar.reducer";

export const getInitialState = (filterTabs?: FilterTab[]) => {
  const tabs = filterTabs || initialState.filterTabs;
  const activeTab = tabs[0]?.value ?? initialState.activeTab;

  return {
    ...initialState,
    filterTabs: tabs,
    activeTab: activeTab,
  };
};

import { EducationLevelEnum } from "~/bff/graphql/generated/graphql";

export const educationLevelMap: { [key in EducationLevelEnum]: string } = {
  [EducationLevelEnum.HighSchoolDegree]: "High School",
  [EducationLevelEnum.Associates]: "Associates",
  [EducationLevelEnum.Certificate]: "Certificate",
  [EducationLevelEnum.AdvancedCertificate]: "Advanced Certificate",
  [EducationLevelEnum.Bachelors]: "Bachelors",
  [EducationLevelEnum.Masters]: "Masters",
  [EducationLevelEnum.Doctorate]: "Doctorate",
  [EducationLevelEnum.PostdoctoralStudies]: "Postdoctoral Studies",
  [EducationLevelEnum.NonDegreeSeeking]: "Non-Degree Seeking",
  [EducationLevelEnum.TechnicalDiploma]: "Technical Diploma",
};

import PhoneInputWithCountrySelect from "react-phone-number-input/react-hook-form";
import { cn } from "~/utils/cn";
import { PhoneFieldProps } from "./phone-field.types";
import { CountrySelect } from "./components/country-select";
import { Container } from "./components/container";

export const PhoneField = ({
  error,
  label,
  onChange,
  onBlur,
  containerClassName,
  required,
  ...props
}: PhoneFieldProps) => {
  return (
    <div
      data-error={!!error}
      className={cn("group flex flex-col pt-0.5", containerClassName)}
    >
      {label && (
        <span className="text-sm/6 font-normal text-neutral-primary">
          {label}
          {required && "*"}
        </span>
      )}
      <PhoneInputWithCountrySelect
        {...props}
        defaultCountry="US"
        containerComponent={Container}
        countrySelectComponent={CountrySelect}
        id={props.name}
        aria-label={props.placeholder}
      />
      {error && (
        <span className="mt-1 flex items-center text-xs/6 text-status-critical">
          {error}
        </span>
      )}
    </div>
  );
};

import { SourceNameEnum } from "~/bff/graphql/generated/graphql";

export const sourceNameMap: { [key in SourceNameEnum]: string } = {
  [SourceNameEnum.CareersPageOrEmbed]: "Careers Page or Embed",
  [SourceNameEnum.Custom]: "Custom",
  [SourceNameEnum.Indeed]: "Indeed",
  [SourceNameEnum.Linkedin]: "LinkedIn",
  [SourceNameEnum.OrganicTraffic]: "Organic Traffic",
  [SourceNameEnum.PastCandidates]: "Past Candidates",
  [SourceNameEnum.ScalisJobBoard]: "Scalis Job Board",
  [SourceNameEnum.ScalisMatch]: "Scalis Match",
  [SourceNameEnum.Ziprecruiter]: "ZipRecruiter",
};

import { RejectionReasonEnum } from "~/bff/graphql/generated/graphql";

export const rejectionReasonMap: { [key in RejectionReasonEnum]: string } = {
  [RejectionReasonEnum.BetterQualifiedCandidatesChosenEducation]:
    "Better Qualified Candidates Chosen - Education",
  [RejectionReasonEnum.BetterQualifiedCandidatesChosenSkills]:
    "Better Qualified Candidates Chosen - Skills",
  [RejectionReasonEnum.BetterQualifiedCandidatesChosenExperience]:
    "Better Qualified Candidates Chosen - Experience",
  [RejectionReasonEnum.CandidateAppliedToWrongPosition]:
    "Candidate Applied To Wrong Position",
  [RejectionReasonEnum.CandidateWithdrawn]: "Candidate Withdrawn",
  [RejectionReasonEnum.DeclinedInterview]: "Declined Interview",
  [RejectionReasonEnum.DoesntMeetMinimumQualifications]:
    "Doesn't Meet Minimum Qualifications",
  [RejectionReasonEnum.DuplicateApplication]: "Duplicate Application",
  [RejectionReasonEnum.FailedAssessment]: "Failed Assessment",
  [RejectionReasonEnum.FailedBackgroundCheck]: "Failed Background Check",
  [RejectionReasonEnum.FailedDrugTest]: "Failed Drug Test",
  [RejectionReasonEnum.FailedToCompleteRequiredAssessment]:
    "Failed To Complete Required Assessment",
  [RejectionReasonEnum.FailedToRespond]: "Failed To Respond",
  [RejectionReasonEnum.ImpracticalJobExpectations]:
    "Impractical Job Expectations",
  [RejectionReasonEnum.InflatedResponsibilities]: "Inflated Responsibilities",
  [RejectionReasonEnum.InterviewNoShow]: "Interview No Show",
  [RejectionReasonEnum.JobNotAlignedWithCareerObjectives]:
    "Job Not Aligned With Career Objectives",
  [RejectionReasonEnum.LackOfRelevantExperience]: "Lack Of Relevant Experience",
  [RejectionReasonEnum.LackOfRelevantSkillsAndAbilities]:
    "Lack Of Relevant Skills And Abilities",
  [RejectionReasonEnum.LackedClarityInAnswers]: "Lacked Clarity In Answers",
  [RejectionReasonEnum.LackedSpecificTechnicalKnowledge]:
    "Lacked Specific Technical Knowledge",
  [RejectionReasonEnum.LimitedAvailabilityOrSchedulingConflicts]:
    "Limited Availability Or Scheduling Conflicts",
  [RejectionReasonEnum.MissingRequiredCertifications]:
    "Missing Required Certifications",
  [RejectionReasonEnum.NegativeReferences]: "Negative References",
  [RejectionReasonEnum.OfferRejectedDoesntMeetSalaryExpectations]:
    "Offer Rejected - Doesn't Meet Salary Expectations",
  [RejectionReasonEnum.OfferRejectedJobLocation]:
    "Offer Rejected - Job Location",
  [RejectionReasonEnum.OfferRejectedJobSchedule]:
    "Offer Rejected - Job Schedule",
  [RejectionReasonEnum.OfferRejectedOther]: "Offer Rejected - Other",
  [RejectionReasonEnum.Other]: "Other",
  [RejectionReasonEnum.OverqualifiedForPosition]: "Overqualified For Position",
  [RejectionReasonEnum.PoorCommunicationSkills]: "Poor Communication Skills",
  [RejectionReasonEnum.PoorInterviewPerformance]: "Poor Interview Performance",
  [RejectionReasonEnum.PositionAlreadyFilled]: "Position Already Filled",
  [RejectionReasonEnum.PositionEliminated]: "Position Eliminated",
  [RejectionReasonEnum.PositionOnHold]: "Position On Hold",
  [RejectionReasonEnum.RequiresVisaSponsorship]: "Requires Visa Sponsorship",
  [RejectionReasonEnum.SalaryExpectationsTooHigh]:
    "Salary Expectations Too High",
  [RejectionReasonEnum.UnableToRelocateAsRequired]:
    "Unable To Relocate As Required",
  [RejectionReasonEnum.UnprofessionalBehaviorDuringHiringProcess]:
    "Unprofessional Behavior During Hiring Process",
};

export const generateOptionsFromEnumMap = <T extends Record<string, string>>(
  enumObject: T,
) => {
  return Object.keys(enumObject).map(key => ({
    value: key as keyof T,
    label: enumObject[key],
  })) as {
    value: keyof T;
    label: string;
  }[];
};

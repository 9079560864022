import { castArray } from "lodash";
import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
} from "~/bff/graphql/generated/graphql";
import { QueryFilters } from "~/scalis-components/team/contexts/filters-context";
import { FormValues } from "./candidate-application-filters-context.schema";
import { CandidateApplicationFilterTab } from "./candidate-application-filters-context.types";

const updateListFilter = <K extends keyof QueryFilters>(
  key: K,
  filters: QueryFilters,
  value: NonNullable<QueryFilters[K]>,
): QueryFilters => {
  const curFilterValue = filters[key]
    ? castArray<QueryFilters[K]>(filters[key])
    : [];
  const newValue = Array.isArray(value) ? value : [value];

  return {
    ...filters,
    [key]: [...new Set([...curFilterValue, ...newValue])],
  };
};

const getMatchScoreFilter = (matchScore: [number, number]) => {
  if (!matchScore) return undefined;

  const [min, max] = matchScore;
  if (min === 0 && max === 100) return undefined;

  return { min, max };
};

const getListFilter = <T>(filterValue: Array<T>) => {
  if (filterValue.length) return filterValue;
  return undefined;
};

const getBooleanFilter = (filterValue: "0" | "1" | null) => {
  if (!filterValue) return undefined;
  return Boolean(Number(filterValue));
};

const getNumberFilter = (filterValue?: string) => {
  if (!filterValue) return undefined;
  return Number(filterValue);
};

const applyTabFilters = (
  filters: QueryFilters,
  activeTab?: string,
): QueryFilters => {
  switch (activeTab) {
    case CandidateApplicationFilterTab.ACTIVE_CANDIDATES:
      return updateListFilter(
        "applicationStatusNotIn",
        filters,
        ApplicationStatusEnum.Rejected,
      );
    case CandidateApplicationFilterTab.SCALIS_CANDIDATES:
      return updateListFilter(
        "applicationType",
        filters,
        ApplicationTypeEnum.Dynamic,
      );
    case CandidateApplicationFilterTab.UPLOADED:
      return updateListFilter(
        "applicationType",
        filters,
        ApplicationTypeEnum.Static,
      );
    case CandidateApplicationFilterTab.INVITED_TO_APPLY: {
      return updateListFilter(
        "applicationStatus",
        filters,
        ApplicationStatusEnum.InvitedToApply,
      );
    }
    case CandidateApplicationFilterTab.REJECTED: {
      return updateListFilter(
        "applicationStatus",
        filters,
        ApplicationStatusEnum.Rejected,
      );
    }
    default:
      return filters;
  }
};

export const mapRawFiltersToQueryFilters = (
  values: FormValues,
  activeTab?: string,
): QueryFilters => {
  const filters: QueryFilters = {
    matchScore: getMatchScoreFilter(values.matchScore),
    candidateProfileId: getListFilter(
      Array.from(new Set([...values.candidateProfileId, ...values.emails])).map(
        Number,
      ),
    ),
    skills: getListFilter(values.skills),
    lastActivity: values.lastActivity,
    applicationStatus: getListFilter(values.applicationStatus),
    sourceName: getListFilter(values.sourceName),
    applicationType: getListFilter(values.candidateType),
    medalistDesignation: getListFilter(values.medalistDesignation),
    creditedCompanyUserIds: getListFilter(values.screenedBy.map(Number)),
    educationLevel: getListFilter(values.degrees),
    timezones: getListFilter(values.timezones),
    companies: getListFilter(values.companies),
    majors: getListFilter(values.majors),
    jobTitles: getListFilter(values.jobTitles),
    veteranStatus: getListFilter(values.veteranStatus),
    willingToRelocate: getListFilter(values.willingToRelocate),
    nonTraditionalStatus: getBooleanFilter(values.nonTraditional),
    schools: getListFilter(values.schools),
    yearsOfExperience: getNumberFilter(values.yearsOfExperience),
    ...(values.search && values.search !== "" ? { search: values.search } : {}),
  };

  return applyTabFilters(filters, activeTab);
};

"use client";

import { createContext, useReducer } from "react";
import { Tab } from "../components/filter-tabs/filter-tabs.types";
import {
  FilterBarActionType,
  FilterBarContextProviderProps,
  FilterBarContextType,
} from "./filter-bar-context.types";
import { filterBarReducer } from "./filter-bar.reducer";
import { getInitialState } from "./filter-bar.utils";

export const FilterBarContext = createContext<FilterBarContextType | null>(
  null,
);

export const FilterBarContextProvider = ({
  filterTabs,
  children,
}: FilterBarContextProviderProps) => {
  const [state, dispatch] = useReducer(
    filterBarReducer,
    getInitialState(filterTabs),
  );

  const setActiveTab = (tab: string) => {
    dispatch({
      type: FilterBarActionType.SET_ACTIVE_TAB,
      payload: tab,
    });
  };

  const setSearch = (search: string) => {
    dispatch({
      type: FilterBarActionType.SET_SEARCH,
      payload: search,
    });
  };

  const setFilterTabs = (filterTabs: Tab[]) => {
    dispatch({
      type: FilterBarActionType.SET_FILTER_TABS_LOADING,
    });

    dispatch({
      type: FilterBarActionType.SET_FILTER_TABS,
      payload: filterTabs,
    });
  };

  const setIsFilterDrawerOpen = (isOpen: boolean) => {
    dispatch({
      type: FilterBarActionType.SET_IS_FILTER_DRAWER_OPEN,
      payload: isOpen,
    });
  };

  return (
    <FilterBarContext.Provider
      value={{
        activeTab: state.activeTab,
        setActiveTab,
        search: state.search,
        setSearch,
        isLoadingFilterTabs: state.isLoadingFilterTabs,
        filterTabs: state.filterTabs,
        setFilterTabs,
        isFilterDrawerOpen: state.isFilterDrawerOpen,
        setIsFilterDrawerOpen,
      }}
    >
      {children}
    </FilterBarContext.Provider>
  );
};

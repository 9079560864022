import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useGetPlaceDetails } from "./useGetPlaceDetails";

const useAddress = (): {
  address: {
    address_line_1: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
  } | null;
  setPlaceId: Dispatch<SetStateAction<string>>;
} => {
  const [placeId, setPlaceId] = useState("");
  const { data } = useGetPlaceDetails(placeId);

  const address = useMemo(() => {
    if (!data) return null;

    const currentAddress = {
      address_line_1: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    };

    const reduced: Record<string, string> = data.address_components.reduce(
      (acc, { long_name, types: [type] }) => {
        return {
          ...acc,
          [type]: long_name,
        };
      },
      {},
    );

    currentAddress.address_line_1 =
      `${reduced["street_number"] || ""} ${reduced["route"] || ""}`.trim() ||
      "";
    currentAddress.city = reduced["locality"] ?? "";
    currentAddress.state = reduced["administrative_area_level_1"] ?? "";
    currentAddress.postalCode = reduced["postal_code"] ?? "";
    currentAddress.country = reduced["country"] ?? "";

    return currentAddress;
  }, [data]);

  return { address, setPlaceId };
};

export default useAddress;

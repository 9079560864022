import {
  FilterContentOption,
  Option,
} from "./use-get-candidate-application-filters-data.types";

const getOptionValue = (option: FilterContentOption, idAsValue?: boolean) => {
  if (idAsValue) return option.id?.toString() ?? "";
  return option.value;
};

export const mapToOptions = (
  filterContentOptions?: FilterContentOption[],
  options?: { idAsValue?: boolean },
): Option[] => {
  return (
    filterContentOptions?.map(option => ({
      value: getOptionValue(option, options?.idAsValue),
      label: option.value,
    })) ?? []
  );
};

import {
  FilterBarAction,
  FilterBarActionType,
  FilterBarReducer,
} from "./filter-bar-context.types";

export const initialState: FilterBarReducer = {
  activeTab: undefined,
  search: "",
  isLoadingFilterTabs: true,
  filterTabs: [],
  isFilterDrawerOpen: false,
};

export const filterBarReducer = (
  state: FilterBarReducer,
  action: FilterBarAction,
): FilterBarReducer => {
  switch (action.type) {
    case FilterBarActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case FilterBarActionType.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    case FilterBarActionType.SET_FILTER_TABS_LOADING:
      return {
        ...state,
        isLoadingFilterTabs: true,
      };

    case FilterBarActionType.SET_FILTER_TABS: {
      if (state.activeTab == null) {
        return {
          ...state,
          activeTab: action.payload[0].value,
          filterTabs: action.payload,
          isLoadingFilterTabs: false,
        };
      }

      return {
        ...state,
        filterTabs: action.payload,
      };
    }

    case FilterBarActionType.SET_IS_FILTER_DRAWER_OPEN:
      return {
        ...state,
        isFilterDrawerOpen: action.payload,
      };

    default:
      return state;
  }
};

import {
  applicationStatusMap,
  candidateTypeMap,
  generateOptionsFromEnumMap,
  sourceNameMap,
  veteranStatusMap,
  willingToRelocateMap,
} from "~/utils/enum-mappers";

export const FIELD_NAME_SEARCH = "search";
export const FIELD_NAME_LOCATION = "location";
export const FIELD_NAME_MATCH_SCORE = "matchScore";
export const FIELD_NAME_EMAIL = "emails";
export const FIELD_NAME_SCREENED_BY = "screenedBy";
export const FIELD_NAME_SOURCE_NAME = "sourceName";
export const FIELD_NAME_PHONE = "phone";
export const FIELD_NAME_APPLICATION_STATUS = "applicationStatus";
export const FIELD_NAME_CANDIDATE_TYPE = "candidateType";
export const FIELD_NAME_BOOLEAN_SEARCH = "booleanSearch";
export const FIELD_NAME_MEDALIST_DESIGNATION = "medalistDesignation";
export const FIELD_NAME_SCREENING_QUESTIONS = "screeningQuestions";
export const FIELD_NAME_DEGREE = "degrees";
export const FIELD_NAME_CANDIDATE_TIME_ZONE = "timezones";
export const FIELD_NAME_SKILLS = "skills";
export const FIELD_NAME_COMPANY = "companies";
export const FIELD_NAME_MAJORS = "majors";
export const FIELD_NAME_JOB_TITLE = "jobTitles";
export const FIELD_NAME_VETERAN_STATUS = "veteranStatus";
export const FIELD_NAME_WILLING_TO_RELOCATE = "willingToRelocate";
export const FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY = "nonTraditional";
export const FIELD_NAME_SCHOOLS = "schools";
export const FIELD_NAME_CANDIDATE_PROFILE_ID = "candidateProfileId";
export const FIELD_NAME_YEARS_OF_EXPERIENCE = "yearsOfExperience";
export const FIELD_NAME_LAST_ACTIVITY_OPTION = "lastActivityOption";
export const FIELD_NAME_LAST_ACTIVITY = "lastActivity";

export const FORM_FIELDS = {
  [FIELD_NAME_SEARCH]: {
    label: "Search",
    placeholder: "Search for keywords",
  },
  [FIELD_NAME_LOCATION]: {
    label: "Location",
  },
  [FIELD_NAME_MATCH_SCORE]: {
    label: "Match Score",
  },
  [FIELD_NAME_EMAIL]: {
    label: "Email",
  },
  [FIELD_NAME_SCREENED_BY]: {
    label: "Screened By",
    term: "employer",
  },
  [FIELD_NAME_SOURCE_NAME]: {
    label: "Source",
    options: generateOptionsFromEnumMap(sourceNameMap),
  },
  [FIELD_NAME_PHONE]: {
    label: "Phone Number",
  },
  [FIELD_NAME_APPLICATION_STATUS]: {
    label: "Status",
    options: generateOptionsFromEnumMap(applicationStatusMap),
  },
  [FIELD_NAME_CANDIDATE_TYPE]: {
    label: "Candidate Type",
    options: generateOptionsFromEnumMap(candidateTypeMap),
  },
  [FIELD_NAME_BOOLEAN_SEARCH]: {
    label: "Boolean Search",
  },
  [FIELD_NAME_MEDALIST_DESIGNATION]: {
    label: "Medalist Designation",
    options: [
      { label: "Silver Medalist", value: "SILVER" },
      { label: "Bronze Medalist", value: "BRONZE" },
    ],
  },
  [FIELD_NAME_SCREENING_QUESTIONS]: {
    label: "Screening Questions",
    options: [],
  },
  [FIELD_NAME_DEGREE]: {
    label: "Degree",
    optionsName: "degrees",
  },
  [FIELD_NAME_CANDIDATE_TIME_ZONE]: {
    label: "Candidate Time Zone",
  },
  [FIELD_NAME_SKILLS]: {
    label: "Skills",
    term: "skill",
  },
  [FIELD_NAME_COMPANY]: {
    label: "Company",
  },
  [FIELD_NAME_MAJORS]: {
    label: "Major",
  },
  [FIELD_NAME_JOB_TITLE]: {
    label: "Job Title",
  },
  [FIELD_NAME_VETERAN_STATUS]: {
    label: "Veteran Status",
    options: generateOptionsFromEnumMap(veteranStatusMap),
  },
  [FIELD_NAME_WILLING_TO_RELOCATE]: {
    label: "Willing to Relocate",
    options: generateOptionsFromEnumMap(willingToRelocateMap),
  },
  [FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY]: {
    label: "Non-Traditional Work History",
  },
  [FIELD_NAME_SCHOOLS]: {
    label: "School",
  },
  [FIELD_NAME_CANDIDATE_PROFILE_ID]: {
    label: "Candidate Name",
    emptyTerm: "candidates",
  },
  [FIELD_NAME_YEARS_OF_EXPERIENCE]: {
    label: "Years of Experience",
    placeholder: "Ex: 5",
  },
  [FIELD_NAME_LAST_ACTIVITY_OPTION]: {
    label: "Last Activity Date",
    options: [
      {
        label: "Last 7 Days",
        value: "last_7_days",
      },
      {
        label: "Last 30 Days",
        value: "last_30_days",
      },
      {
        label: "This Month",
        value: "this_month",
      },
      {
        label: "Last Month",
        value: "last_month",
      },
      {
        label: "This Quarter",
        value: "this_quarter",
      },
      {
        label: "Last Quarter",
        value: "last_quarter",
      },
      {
        label: "This Year",
        value: "this_year",
      },
      {
        label: "Last Year",
        value: "last_year",
      },
    ],
  },
};

import { useContext } from "react";
import { FilterBarContext } from "./filter-bar-context-provider";

export const useFilterBarContext = () => {
  const context = useContext(FilterBarContext);
  if (context == null) {
    throw new Error(
      "useFilterBarContext must be used within a FilterBarContextProvider",
    );
  }

  return context;
};

import { z } from "zod";
import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
  EducationLevelEnum,
  SourceNameEnum,
  VeteranStatusEnum,
  WillingToRelocateEnum,
} from "~/bff/graphql/generated/graphql";
import {
  FIELD_NAME_CANDIDATE_PROFILE_ID,
  FIELD_NAME_CANDIDATE_TIME_ZONE,
  FIELD_NAME_CANDIDATE_TYPE,
  FIELD_NAME_COMPANY,
  FIELD_NAME_DEGREE,
  FIELD_NAME_EMAIL,
  FIELD_NAME_APPLICATION_STATUS,
  FIELD_NAME_JOB_TITLE,
  FIELD_NAME_LAST_ACTIVITY_OPTION,
  FIELD_NAME_LAST_ACTIVITY,
  FIELD_NAME_LOCATION,
  FIELD_NAME_MAJORS,
  FIELD_NAME_MATCH_SCORE,
  FIELD_NAME_MEDALIST_DESIGNATION,
  FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY,
  FIELD_NAME_PHONE,
  FIELD_NAME_SCHOOLS,
  FIELD_NAME_SCREENED_BY,
  FIELD_NAME_SCREENING_QUESTIONS,
  FIELD_NAME_SEARCH,
  FIELD_NAME_SKILLS,
  FIELD_NAME_SOURCE_NAME,
  FIELD_NAME_VETERAN_STATUS,
  FIELD_NAME_WILLING_TO_RELOCATE,
  FIELD_NAME_YEARS_OF_EXPERIENCE,
  FIELD_NAME_BOOLEAN_SEARCH,
} from "./candidate-application-filters-context.constants";

export type FormValues = z.infer<typeof formSchema>;

export const formSchema = z.object({
  [FIELD_NAME_SEARCH]: z.string().optional(),
  [FIELD_NAME_LOCATION]: z.array(z.string()),
  [FIELD_NAME_MATCH_SCORE]: z.tuple([z.number(), z.number()]),
  [FIELD_NAME_EMAIL]: z.array(z.string()),
  [FIELD_NAME_SCREENED_BY]: z.array(z.string()),
  [FIELD_NAME_SOURCE_NAME]: z.array(z.nativeEnum(SourceNameEnum)),
  [FIELD_NAME_PHONE]: z.string().optional(),
  [FIELD_NAME_APPLICATION_STATUS]: z.array(z.nativeEnum(ApplicationStatusEnum)),
  [FIELD_NAME_CANDIDATE_TYPE]: z.array(z.nativeEnum(ApplicationTypeEnum)),
  [FIELD_NAME_BOOLEAN_SEARCH]: z.array(z.string()),
  [FIELD_NAME_MEDALIST_DESIGNATION]: z.array(z.string()),
  [FIELD_NAME_SCREENING_QUESTIONS]: z
    .string()
    .optional()
    .transform(val => (val ? Number(val) : undefined))
    .pipe(z.number().optional()),
  [FIELD_NAME_DEGREE]: z.array(z.nativeEnum(EducationLevelEnum)),
  [FIELD_NAME_CANDIDATE_TIME_ZONE]: z.array(z.string()),
  [FIELD_NAME_SKILLS]: z.array(z.string()),
  [FIELD_NAME_COMPANY]: z.array(z.string()),
  [FIELD_NAME_MAJORS]: z.array(z.string()),
  [FIELD_NAME_JOB_TITLE]: z.array(z.string()),
  [FIELD_NAME_VETERAN_STATUS]: z.array(z.nativeEnum(VeteranStatusEnum)),
  [FIELD_NAME_WILLING_TO_RELOCATE]: z.array(
    z.nativeEnum(WillingToRelocateEnum),
  ),
  [FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY]: z.enum(["1", "0"]).nullable(),
  [FIELD_NAME_SCHOOLS]: z.array(z.string()),
  [FIELD_NAME_CANDIDATE_PROFILE_ID]: z.array(z.string()),
  [FIELD_NAME_YEARS_OF_EXPERIENCE]: z.string().optional(),
  [FIELD_NAME_LAST_ACTIVITY_OPTION]: z.string().optional(),
  [FIELD_NAME_LAST_ACTIVITY]: z
    .object({ from: z.date(), to: z.date() })
    .optional(),
});

export const formDefaultValues: FormValues = {
  [FIELD_NAME_SEARCH]: "",
  [FIELD_NAME_LOCATION]: [],
  [FIELD_NAME_MATCH_SCORE]: [0, 100],
  [FIELD_NAME_EMAIL]: [],
  [FIELD_NAME_SCREENED_BY]: [],
  [FIELD_NAME_SOURCE_NAME]: [],
  [FIELD_NAME_PHONE]: "",
  [FIELD_NAME_APPLICATION_STATUS]: [],
  [FIELD_NAME_CANDIDATE_TYPE]: [],
  [FIELD_NAME_BOOLEAN_SEARCH]: [],
  [FIELD_NAME_MEDALIST_DESIGNATION]: [],
  [FIELD_NAME_SCREENING_QUESTIONS]: undefined,
  [FIELD_NAME_DEGREE]: [],
  [FIELD_NAME_CANDIDATE_TIME_ZONE]: [],
  [FIELD_NAME_SKILLS]: [],
  [FIELD_NAME_COMPANY]: [],
  [FIELD_NAME_MAJORS]: [],
  [FIELD_NAME_JOB_TITLE]: [],
  [FIELD_NAME_VETERAN_STATUS]: [],
  [FIELD_NAME_WILLING_TO_RELOCATE]: [],
  [FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY]: null,
  [FIELD_NAME_SCHOOLS]: [],
  [FIELD_NAME_CANDIDATE_PROFILE_ID]: [],
  [FIELD_NAME_YEARS_OF_EXPERIENCE]: "",
  [FIELD_NAME_LAST_ACTIVITY_OPTION]: undefined,
  [FIELD_NAME_LAST_ACTIVITY]: undefined,
};

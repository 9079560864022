import { PropsWithChildren } from "react";
import { Tab } from "../components/filter-tabs/filter-tabs.types";

export type FilterBarReducer = {
  activeTab?: string;
  search: string;
  isLoadingFilterTabs: boolean;
  filterTabs: Tab[];
  isFilterDrawerOpen: boolean;
};

export enum FilterBarActionType {
  SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
  SET_SEARCH = "SET_SEARCH",
  SET_FILTER_TABS_LOADING = "SET_FILTER_TABS_LOADING",
  SET_FILTER_TABS = "SET_FILTER_TABS",
  SET_IS_FILTER_DRAWER_OPEN = "SET_IS_FILTER_DRAWER_OPEN",
}

export type FilterBarAction =
  | {
      type: FilterBarActionType.SET_ACTIVE_TAB;
      payload: string;
    }
  | {
      type: FilterBarActionType.SET_SEARCH;
      payload: string;
    }
  | {
      type: FilterBarActionType.SET_FILTER_TABS_LOADING;
    }
  | {
      type: FilterBarActionType.SET_FILTER_TABS;
      payload: Tab[];
    }
  | {
      type: FilterBarActionType.SET_IS_FILTER_DRAWER_OPEN;
      payload: boolean;
    };

export type FilterBarContextType = {
  activeTab?: string;
  setActiveTab: (tab: string) => void;
  search: string;
  setSearch: (search: string) => void;
  isLoadingFilterTabs: boolean;
  filterTabs: Tab[];
  setFilterTabs: (filterTabs: Tab[]) => void;
  isFilterDrawerOpen: boolean;
  setIsFilterDrawerOpen: (isOpen: boolean) => void;
};

export type FilterTab = { label: string; value: string };

export type FilterBarContextProviderProps = PropsWithChildren<{
  filterTabs?: FilterTab[];
}>;

import { RaceEthnicityEnum } from "~/bff/graphql/generated/graphql";

export const raceEthnicityMap: { [key in RaceEthnicityEnum]: string } = {
  [RaceEthnicityEnum.AmericanIndianOrAlaskaNative]:
    "American Indian Or Alaska Native",
  [RaceEthnicityEnum.Asian]: "Asian",
  [RaceEthnicityEnum.BlackOrAfricanAmerican]: "Black Or African American",
  [RaceEthnicityEnum.DeclineToSelfIdentify]: "Decline To Self Identify",
  [RaceEthnicityEnum.HispanicOrLatino]: "Hispanic Or Latino",
  [RaceEthnicityEnum.NativeHawaiianOrOtherPacificIslander]:
    "Native Hawaiian Or Other Pacific Islander",
  [RaceEthnicityEnum.TwoOrMoreRaces]: "Two Or More Races",
  [RaceEthnicityEnum.White]: "White",
};

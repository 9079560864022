import { PropsWithChildren } from "react";
import { GetCandidateApplicationQueryVariables } from "~/bff/graphql/generated/graphql";
import { CandidateApplicationFiltersData } from "~/src/app/company/(dashboard)/(applications)/pipeline/hooks/use-get-candidate-application-filters-data";

export type CandidateApplicationFiltersContextProps = {
  filters: GetCandidateApplicationQueryVariables;
  filtersData: CandidateApplicationFiltersData;
};

export type CandidateApplicationFiltersContextProviderProps =
  PropsWithChildren<{
    filterTabs: Array<{ label: string; value: string }>;
  }>;

export type CandidateApplicationFiltersContextProviderInternalProps = Omit<
  CandidateApplicationFiltersContextProviderProps,
  "filterTabs"
>;

export enum CandidateApplicationFilterTab {
  ACTIVE_CANDIDATES = "active-candidates",
  SCALIS_CANDIDATES = "scalis-candidates",
  UPLOADED = "uploaded",
  INVITED_TO_APPLY = "invited-to-apply",
  REJECTED = "rejected",
}

"use client";

import { createContext, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { debounce } from "lodash";
import {
  FiltersContextProps,
  FiltersContextProviderProps,
  QueryFilters,
} from "./filters-context.types";
import {
  FilterBarContextProvider,
  useFilterBarContext,
} from "../../filter-bar/contexts";

export const FiltersContext = createContext<FiltersContextProps<any>>({
  form: undefined,
  filters: {},
  setFilters: () => {},
  updateFilters: () => {},
});

const FiltersContextProviderInternal = <T extends z.ZodType<any>>({
  formSchema,
  formDefaultValues,
  mapRawFiltersToQueryFilters,
  children,
}: FiltersContextProviderProps<T>) => {
  const { activeTab } = useFilterBarContext();

  const form = useForm<z.infer<T>>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });

  const [filters, setFilters] = useState<QueryFilters>({});

  const updateFilters = (partialFilters: Partial<QueryFilters>) => {
    return setFilters(curFilters => ({
      ...curFilters,
      ...partialFilters,
    }));
  };

  const updateQueryFilters = useCallback(
    (values: z.infer<T>, tab?: string) => {
      setFilters(mapRawFiltersToQueryFilters(values, tab ?? ""));
    },
    [mapRawFiltersToQueryFilters],
  );

  useEffect(() => {
    const debouncedUpdate = debounce(updateQueryFilters, 400);

    const formReturn = form?.watch(values => {
      // Type casting until the next version: https://github.com/react-hook-form/react-hook-form/issues/9919
      return debouncedUpdate(values as z.infer<T>, activeTab);
    });

    updateQueryFilters(form?.getValues(), activeTab);

    return () => formReturn?.unsubscribe();
  }, [updateQueryFilters, form, activeTab]);

  return (
    <FiltersContext.Provider
      value={{ form, filters, setFilters, updateFilters }}
    >
      <FormProvider {...form}>{children}</FormProvider>
    </FiltersContext.Provider>
  );
};

const FiltersContextProviderWrapper = <T extends z.ZodType<any>>({
  formSchema,
  formDefaultValues,
  mapRawFiltersToQueryFilters,
  filterTabs,
  children,
}: FiltersContextProviderProps<T>) => {
  return (
    <FilterBarContextProvider filterTabs={filterTabs}>
      <FiltersContextProviderInternal
        formSchema={formSchema}
        formDefaultValues={formDefaultValues}
        mapRawFiltersToQueryFilters={mapRawFiltersToQueryFilters}
      >
        {children}
      </FiltersContextProviderInternal>
    </FilterBarContextProvider>
  );
};

export { FiltersContextProviderWrapper as FiltersContextProvider };

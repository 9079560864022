import { useContext } from "react";
import { FiltersContext } from "./filters-context";

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error(
      "useFiltersContext must be used within a FiltersContextProvider",
    );
  }

  return context;
};

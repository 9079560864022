import { Icon } from "~/scalis-components/core/icon";
import { CountrySelectProps } from "./country-select.types";

export const CountrySelect: React.FC<CountrySelectProps> = ({
  options,
  onChange,
  value,
  name,
  onFocus,
  onBlur,
  disabled,
}) => {
  return (
    <div className="relative w-16 overflow-hidden text-neutral-primary">
      <select
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        className="opacity-0"
      >
        {options.map(({ value, label }: { value: string; label: string }) => (
          <option key={value || "ZZ"} value={value || "ZZ"}>
            {label}
          </option>
        ))}
      </select>
      <span className="pointer-events-none absolute top-0 flex w-full items-center text-sm font-medium text-neutral-primary">
        {value}
        <Icon icon="fa-solid fa-chevron-down text-neutral-primary text-sm" />
      </span>
    </div>
  );
};

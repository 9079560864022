import { useContext } from "react";
import { CandidateApplicationFiltersContext } from "./candidate-application-filters-context";

export const useCandidateApplicationFiltersContext = () => {
  const context = useContext(CandidateApplicationFiltersContext);

  if (!context) {
    throw new Error(
      "useCandidateApplicationFiltersContext must be used within a CandidateApplicationFiltersContextProvider",
    );
  }

  return context;
};
